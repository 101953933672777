import { createRouter, createWebHistory } from "vue-router";

const routes = [
  {
    path: "/dashboard",
    name: "Dashboard",
    component: () => import("../views/DashboardView.vue"), 
    meta: {
      title: "Dashboard",
    },
    children: [
      {
        path: "jobs",
        name: "dashboard-jobs",
        component: () => import("../views/Jobs.vue"), 
        meta: {
          title: "Jobs",
          requiresAuth: true,
        },
      },
      {
        path: "privacy-policy",
        name: "dashboard-privacy-policy",
        component: () => import("../views/PrivacyPolicyView.vue"), 
        meta: {
          title: "Privacy Policy",
        },

      },
      {
        path: "terms",
        name: "dasboard-terms",
        component: () => import("../views/TermsUseView.vue"),
        meta: {
          title: "Terms Of Use",
        },
      },
      {
        path: "/dashboard/all-review/:id",
        name: "dashboard-all-review",
        component: () => import("../views/ReviewList.vue"),
        meta: {
          requiresAuth: true,
          title: "All Review",
        },
      },
      {
        path: "profile",
        name: "profile-info",
        component: () => import("../views/ProfileInfoView.vue"),
        meta: {
          requiresAuth: true,
          title: "Profile",
        },
      },
      {
        path: "/profile-settings",
        name: "profilesettings",
        component: () => import("../views/ProfileSettings.vue"),
        meta: {
          requiresAuth: true,
          title: "Profile Settings",
        },
        
      },

      {
        path: "/address-list",
        name: "list-address",
        component: () => import("../views/AddressListView.vue"),
        meta: {
          title: "Address",
        },
      },
      {
        path: "/documents",
        name: "documents",
        component: () => import("../views/Documents.vue"),
        meta: {
          requiresAuth: true,
          title: "Documents",
        },
      },

      {
        path: "/packages",
        name: "packages",
        component: () => import("../views/Packages.vue"),
        meta: {
          title: "Packages",
        },
      },

      {
        path: "/payments",
        name: "payments",
        component: () => import("../views/Payments.vue"),
        meta: {
          requiresAuth: true,
          title: "Payments",
        },
      },

      {
        path: "/wallet",
        name: "wallet",
        component: () => import("../views/WalletView.vue"),
        meta: {
          title: "Wallet",
        },
      },
      {
        path: "/contact-us",
        name: "contact-us",
        component: () => import("../views/ContactchatView.vue"),
        meta: {
          title: "Contact Us",
        },
      },
      {
        path: "/add-job",
        name: "addJobdashboard",
        component: () => import("../views/AddJob.vue"),
        meta: {
          requiresAuth: true,
          title: "Add Job",
        },
      },
      
     
    ],
  },
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
    meta: {
      title: "Home",
    },
  },
  {
    path: "/about",
    name: "about",
    component: () => import("../views/AboutView.vue"),
    meta: {
      title: "About Us",
    },
  },
  {
    path: "/services",
    name: "service",
    component: () => import("../views/ServicesView.vue"),
    meta: {
      title: "Services",
    },
  },
  {
    path: "/jobs-listing",
    name: "Jobs Listing",
    component: () => import("../views/JobsView.vue"),
    meta: {
      title: "Jobs Listing",
    },
  },
  {
    path: "/job-detail/:id",
    name: "job-detail",
    component: () => import("../views/JobDetail.vue"),
    meta: {
      title: "Job Details",
    },
  },
  {
    path: "/privacy-policy",
    name: "Privacy Policy",
    component: () => import("../views/PrivacyPolicyView.vue"),
    meta: {
      title: "Privacy Policy",
    },
  },
  {
    path: "/terms",
    name: "Terms Of Use",
    component: () => import("../views/TermsUseView.vue"),
    meta: {
      title: "Terms Of Use",
    },
  },
  {
    path: "/contactus",
    name: "contact",
    component: () => import("../views/ContactView.vue"),
    meta: {
      title: "Contact Us",
    },
  },
  {
    path: "/jobs",
    name: "jobs",
    component: () => import("../views/Jobs.vue"),
    meta: {
      requiresAuth: true,
      title: "Jobs",
    },
  },
  {
    path: "/account",
    name: "account",
    component: () => import("../views/Account.vue"),
    meta: {
      requiresAuth: true,
      title: "Account",
    },
  },
  // {
  //   path: "/profile-settings",
  //   name: "profilesettings",
  //   component: () => import("../views/ProfileSettings.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     title: "Profile Settings",
  //   },
  // },
  // {
  //   path: "/payments",
  //   name: "payments",
  //   component: () => import("../views/Payments.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     title: "Payments",
  //   },
  // },
  {
    path: "/address",
    name: "address",
    component: () => import("../views/Address.vue"),
    meta: {
      title: "Address",
    },
  },
  {
    path: "/sign-up",
    name: "signup",
    component: () => import("../views/SignUp.vue"),
    meta: {
      title: "Sign Up",
    },
  },
  {
    path: "/sign-in",
    name: "signin",
    component: () => import("../views/SignIn.vue"),
    meta: {
      title: "Sign In",
    },
  },
  {
    path: "/forget-password",
    name: "forgetpassword",
    component: () => import("../views/ForgetPassword.vue"),
    meta: {
      title: "Forgot Password",
    },
  },
  {
    path: "/verify-otp",
    name: "verify-otp",
    component: () => import("../views/VerifyOTP"),
    meta: {
      title: "Verify OTP",
    },
  },
  {
    path: "/confirm-password",
    name: "confirmpassword",
    component: () => import("../views/ConfirmPassword.vue"),
    meta: {
      title: "Confirm Password",
    },
  },

  {
    path: "/profile/:id",
    name: "profile",
    component: () => import("../views/Profile.vue"),
    meta: {
      requiresAuth: true,
      title: "Profile",
    },
  },
  {
    path: "/add-job",
    name: "addJob",
    component: () => import("../views/AddJob.vue"),
    meta: {
      requiresAuth: true,
      title: "Add Job",
    },
  },
  {
    path: "/edit-job/:id",
    name: "editJob",
    component: () => import("../views/EditJob.vue"),
    meta: {
      requiresAuth: true,
      title: "Edit Job",
    },
  },
  // {
  //   path: "/documents",
  //   name: "documents",
  //   component: () => import("../views/Documents.vue"),
  //   meta: {
  //     requiresAuth: true,
  //     title: "Documents",
  //   },
  // },
  {
    path: "/notifications",
    name: "Notifications",
    component: () => import("../views/Notifications.vue"),
    meta: {
      requiresAuth: true,
      title: "Notifications",
    },
  },
  {
    path: "/review",
    name: "review",
    component: () => import("../views/Review.vue"),
    meta: {
      requiresAuth: true,
      title: "Review",
    },
  },
  {
    path: "/all-review/:id",
    name: "all-review",
    component: () => import("../views/ReviewList.vue"),
    meta: {
      requiresAuth: true,
      title: "All Review",
    },
  },
  {
    path: "/chat",
    name: "chat",
    component: () => import("../views/Chat.vue"),
    meta: {
      requiresAuth: true,
      title: "Chat",
      hideHeaderFooter: true,
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else if (to.hash) {
      return { selector: to.hash, behavior: "smooth" };
    } else {
      return { top: 0, behavior: "smooth" };
    }
  },
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);
  document.title = `${to.meta.title} | Joply`;
  const user = JSON.parse(localStorage.getItem("user"));
  const isUser = user && user.role === "user";

  if (
    requiresAuth &&
    !localStorage.getItem("user") &&
    !localStorage.getItem("isLogin")
  ) {
    next({ name: "signin" });
  } else if (isUser && to.name === "home") {
    next({ name: "jobs" });
  } else {
    next();
  }
});
export default router;
