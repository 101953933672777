<template>
  <!-- Main Header -->

  <section class="nav-bar-wrapper d-lg-block d-none">
    <CContainer>
      <CRow class="align-items-center">
        <CCol col="4">
          <div v-if="user && user.role === 'user'">
            <router-link :to="{ name: 'dashboard-jobs' }">
              <CImage :src="appLogo" class="img-fluid logo-setting" />
            </router-link>
          </div>
          <div v-else>
            <router-link :to="{ name: 'home' }"
              ><CImage :src="appLogo" class="img-fluid logo-setting"
            /></router-link>
          </div>
        </CCol>
        <CCol col="8">
          <div class="d-flex justify-content-end">
            <div v-if="isLoggedIn">
              <ul class="desktop-notification-list unstyled-list">
                <li>
                  <div
                    class="notifiation-wrapper position-relative notification-btn"
                  >
                    <!-- <Notifications></Notifications> -->
                    <router-link :to="{ name: 'Notifications' }"
                      ><img :src="NotificationBell" alt="notification-bell"
                    /></router-link>
                  </div>
                </li>
                <!-- <li>
                  <div class="profile-wrapper header-profile-wrapper">
                    <CDropdown>
                      <CDropdownToggle v-if="user && user.role === 'user'">
                        <img
                          v-if="user.profileImage != ''"
                          :src="`https://api.joply.de/Profiles/${user.profileImage}`"
                          class="img-fluid logo-setting"
                        />
                        <img v-else :src="avatar" alt="profile" />
                      </CDropdownToggle>
                      <CDropdownToggle v-if="user && user.role === 'partner'">
                        <img
                          v-if="user.profileImage != ''"
                          :src="`https://api.joply.de/Profiles/${user.profileImage}`"
                          class="img-fluid logo-setting"
                        />
                        <img v-else :src="avatar" alt="profile" />
                      </CDropdownToggle>
                      <CDropdownMenu>
                        <router-link
                          class="dropdown-item"
                          :to="{ name: 'account' }"
                          >Account</router-link
                        >
                        <router-link
                          class="dropdown-item"
                          :to="{ name: 'jobs' }"
                          >Jobs</router-link
                        >
                        <router-link
                          class="dropdown-item"
                          type="button"
                          @click="logoutAccount"
                          to=""
                          >Logout</router-link
                        >
                      </CDropdownMenu>
                    </CDropdown>
                  </div>
                </li> -->
                <li>
                  <div class="profile-wrapper header-profile-wrapper">
                    <CDropdown placement="bottom-end" class="custom-dropdown">
                      <CDropdownToggle class="custom-toggle">
                        <div class="profile-display">
                          <img
                            v-if="user.profileImage"
                            :src="getImageUrl(user)"
                            class="profile-image"
                          />
                          <img
                            v-else
                            :src="avatar"
                            alt="profile"
                            class="profile-image"
                          />
                          <div class="profile-details">
                            <span class="profile-welcome">Welcome</span>
                            <span class="profile-name"
                              >{{ user.firstName
                              }}<span class="ps-1"
                                >{{ user.lastName }}
                              </span></span
                            >
                          </div>
                          <CIcon
                            name="cil-chevron-bottom"
                            class="dropdown-icon"
                          />
                        </div>
                      </CDropdownToggle>
                      <CDropdownMenu class="custom-dropdown-menu">
                        <router-link
                          class="dropdown-item"
                          :to="{ name: 'dashboard-jobs' }"
                          >Job Post</router-link
                        >
                        <div class="dropdown-dividers"></div>
                        <router-link
                          class="dropdown-item"
                          :to="{ name: 'account' }"
                          >My Account</router-link
                        >
                        <div class="dropdown-dividers"></div>
                        <router-link
                          class="dropdown-item"
                          type="button"
                          @click="logoutAccount"
                          to=""
                        >
                          Sign Out
                        </router-link>
                      </CDropdownMenu>
                    </CDropdown>
                  </div>
                </li>

                <li>
                  <div class="profile-wrapper header-profile-wrapper lang ms-2">
                    <CDropdown>
                      <CDropdownToggle>
                        <img
                          :src="language"
                          class="img-fluid logo-setting"
                          width="30"
                          heigth="30"
                        />
                      </CDropdownToggle>
                      <CDropdownMenu>
                        <button
                          @click="switchToEnglish"
                          class="dropdown-item with-imgdrop"
                        >
                          <img
                            :src="EnglishFlag"
                            alt="English-flag"
                            class="me-2"
                          />English
                        </button>
                        <button
                          @click="switchToGerman"
                          class="dropdown-item with-imgdrop"
                        >
                          <img
                            :src="GermanFlag"
                            alt="german-flag"
                            class="me-2"
                          />German
                        </button>
                      </CDropdownMenu>
                    </CDropdown>
                  </div>
                </li>
                <!-- <li>
                  <router-link
                    :to="{ name: 'jobs' }"
                    class="ms-2 nav-signup-btn"
                    >Jobs
                  </router-link>
                </li> -->
              </ul>
            </div>
            <div class="ms-2" v-if="!isLoggedIn">
              <router-link :to="{ name: 'signin' }" class="nav-signup-btn"
                >Login
              </router-link>
            </div>
            <div class="ms-2" v-if="!isLoggedIn">
              <router-link
                :to="{ name: 'signup' }"
                class="nav-signup-btn sign-upbtn"
                >Register
              </router-link>
            </div>
          </div>
        </CCol>
      </CRow>
    </CContainer>
  </section>
  <!-- Mobile navigation -->
  <section class="mobile-navigation-wrapper d-lg-none d-block">
    <CContainer>
      <CRow class="align-items-center">
        <CCol xs="6">
          <div v-if="user && user.role === 'user'">
            <router-link :to="{ name: 'jobs' }">
              <CImage :src="appLogo" class="img-fluid logo-setting" />
            </router-link>
          </div>
          <div v-else>
            <router-link :to="{ name: 'home' }"
              ><CImage :src="appLogo" class="img-fluid logo-setting"
            /></router-link>
          </div>
        </CCol>
        <CCol xs="6">
          <CRow class="gx-2">
            <CCol xs="8">
              <div class="profile-wrapper header-profile-wrapper lang text-end">
                <CDropdown>
                  <CDropdownToggle>
                    <img
                      :src="language"
                      class="img-fluid logo-setting"
                      width="30"
                      heigth="30"
                    />
                  </CDropdownToggle>
                  <CDropdownMenu>
                    <button
                      @click="switchToEnglish"
                      class="dropdown-item with-imgdrop"
                    >
                      <img
                        :src="EnglishFlag"
                        alt="english-flag"
                        class="mobile-flagImage me-2"
                      />English
                    </button>
                    <button
                      @click="switchToGerman"
                      class="dropdown-item with-imgdrop"
                    >
                      <img
                        :src="GermanFlag"
                        alt="german-flag"
                        class="mobile-flagImage me-2"
                      />German
                    </button>
                  </CDropdownMenu>
                </CDropdown>
              </div>
            </CCol>
            <CCol xs="4">
              <div class="burger-menu">
                <button
                  @click="
                    () => {
                      visible = !visible;
                    }
                  "
                >
                  <img :src="burgerMenu" alt="burgerMenu" class="img-fluid" />
                </button>
              </div>
            </CCol>
          </CRow>
        </CCol>
      </CRow>
    </CContainer>
  </section>

  <COffcanvas
    placement="end"
    :visible="visible"
    @hide="
      () => {
        visible = !visible;
      }
    "
  >
    <COffcanvasHeader>
      <COffcanvasTitle>Logo</COffcanvasTitle>
      <CCloseButton
        class="text-reset"
        @click="
          () => {
            visible = false;
          }
        "
      />
    </COffcanvasHeader>
    <COffcanvasBody>
      <CRow>
        <CCol xs="12">
          <div class="mobile-nav-list">
            <ul v-if="isLoggedIn">
              <li>
                <router-link class="dropdown-item" :to="{ name: 'account' }"
                  >Account</router-link
                >
              </li>
              <li>
                <router-link class="dropdown-item" :to="{ name: 'jobs' }"
                  >Jobs</router-link
                >
              </li>
              <li>
                <router-link :to="{ name: 'Notifications' }"
                  >Notification</router-link
                >
              </li>
              <li>
                <router-link
                  class="dropdown-item"
                  type="button"
                  @click="logoutAccount"
                  to=""
                  >Logout</router-link
                >
              </li>
            </ul>
            <ul v-if="!isLoggedIn">
              <li>
                <router-link :to="{ name: 'signin' }" class="nav-signup-btn"
                  >Login / Register
                </router-link>
              </li>
            </ul>
          </div>
        </CCol>
      </CRow>
    </COffcanvasBody>
  </COffcanvas>
</template>

<script>
import { CContainer, CCol, CImage, CRow } from "@coreui/vue";
import appLogo from "../assets/app-logo-white.png";
import NotificationBell from "../assets/navbar/notificationBell.png";
import avatar from "../assets/navbar/avatar.png";
import language from "../assets/common/language.png";
import EnglishFlag from "../assets/common/united-kingdom.png";
import GermanFlag from "../assets/common/german-flag.png";
import burgerMenu from "../assets/menu.png";
// import Notifications from "./Notifications.vue";
import { mapGetters, mapActions } from "vuex";
export default {
  name: "HeaderComponent",
  setup() {
    return {
      appLogo,
      NotificationBell,
      avatar,
      language,
      EnglishFlag,
      GermanFlag,
      burgerMenu,
    };
  },

  computed: {
    ...mapGetters(["isLoggedIn", "getUser"]),
    user() {
      return this.getUser;
    },
    imageBaseUrl() {
      return process.env.VUE_APP_IMAGES_BASE_URL;
    },
  },
  components: {
    CContainer,
    CImage,
    CRow,

    // Notifications,
    CCol,
  },
  data() {
    return {
      visible: false,
    };
  },
  methods: {
    getImageUrl(user) {
      if (user.profileImage) {
        return `${this.imageBaseUrl}/Profiles/${user.profileImage}`;
      }
    },
    switchToEnglish() {
      this.$i18n.locale = "en";
      localStorage.setItem("preferredLanguage", "en"); // Update local storage
    },
    switchToGerman() {
      this.$i18n.locale = "de";
      localStorage.setItem("preferredLanguage", "de"); // Update local storage
    },
    ...mapActions(["logoutUser"]),
    logoutAccount() {
      localStorage.removeItem("token");
      localStorage.removeItem("user");
      localStorage.removeItem("isLogin");
      localStorage.removeItem("isLogin");
      localStorage.removeItem("userDocuments");
      localStorage.removeItem("userFiles");
      this.logoutUser();
      this.$toastr.success("Account Logout");
      this.$router.push({ name: "home" });
    },
  },
};
</script>

<style scoped>
.nav-bar-wrapper {
  padding: 10px 0px;
  position: absolute;
  top: 0px;
  width: 100%;
  z-index: 3;
}
.logo-setting {
  width: 110px;
  position: relative;
  top: -1px;
}
.notification-btn img {
  width: 28px;
}
.nav-bg-primary {
  background: var(--primaryColor);
}
.desktop-notification-list li {
  display: inline-block;
}
.notification-btn img {
  width: 28px;
}
.notification-btn:focus {
  outline: none;
}
.notification-btn:hover {
  outline: none;
}
.notification-btn {
  background: transparent;
  border: none;
  margin-right: 10px;
}
.profile-wrapper img {
  width: 36px;
  height: 36px;
  object-fit: cover;
  border-radius: 50%;
}
.profile-wrapper.lang img {
  width: 30px;
  height: 30px;
  object-fit: contain;
  border-radius: 0px;
}
.nav-signup-btn {
  color: #fff;
  background-image: linear-gradient(135deg, #00aeef, #00aeef);
  font-size: 15px;
  padding: 12px 38px;
  border-radius: 12px;
  position: relative;
  overflow: hidden;
  transition: 0.4s all;
  font-weight: 500;
  display: block;
  text-decoration: none;
  text-transform: capitalize;
  font-family: var(--primaryFont);
  font-family: var(--SecondaryFont);
}

.sign-upbtn {
  background: transparent !important;
  border: 2px solid #fff !important;
  font-family: var(--SecondaryFont);
}
.nav-signup-btn:before {
  content: "";
  position: absolute;
  top: 0;
  left: -80px;
  width: 100px;
  height: 50px;
  transform: skew(-25deg);
  animation: main_anim 3s infinite ease-in-out;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.2) 100%
  );
}
@keyframes main_anim {
  0% {
    left: -120px;
    opacity: 0;
  }
  10% {
    opacity: 1;
  }
  100% {
    left: 160px;
    opacity: 0;
  }
}
.profile-wrapper a {
  text-decoration: none;
}
.profile-wrapper a span {
  font-family: var(--primaryFont);
  text-transform: uppercase;
  font-weight: 600;
  margin-left: 5px;
  color: #fff;
}
.desktop-notification-list {
  display: flex;
  align-items: center;
}
.with-imgdrop img {
  width: 40px;
}
.mobile-navigation-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  z-index: 3;
  padding: 10px;
}
.burger-menu button {
  background: transparent;
  border: none;
}
@media (min-width: 320px) and (max-width: 767px) {
  .mobile-flagImage {
    width: 30px;
    height: 30px;
  }
  .mobile-nav-list ul li {
    margin-bottom: 8px;
  }
  .mobile-nav-list ul li a {
    display: block;
    text-decoration: none;
    background: #ececec;
    padding: 10px;
    font-family: var(--primaryFont);
    color: #000;
    font-weight: 400;
    text-transform: uppercase;
    text-align: center;
    transition: all 0.3s ease-in-out;
  }
  .mobile-nav-list ul li a:hover {
    display: block;
    text-decoration: none;
    background: rgba(102, 45, 145, 0.2);
    padding: 10px;
    font-family: var(--primaryFont);
    color: rgba(102, 45, 145, 1);
    font-weight: 400;
    text-transform: uppercase;
    transition: all 0.3s ease-in-out;
  }
}

.custom-dropdown {
  position: relative;
  display: flex;
  align-items: center;
  background: var(--primaryColor);
  padding: 0px 10px 0px 0px;
}
.custom-dropdown .dropdown-menu {
  padding: 10px 20px !important;
  transform: translate3d(4.4px, 55.2px, 0px) !important;
  width: 205px !important;
}
.custom-dropdown .dropdown-toggle {
  display: flex;
  justify-content: center;
  align-items: center;
  /* padding: 10px 20px; */
}

.profile-wrapper {
  text-align: center;
  position: relative;
}

.custom-dropdown .dropdown-menu {
  background: var(--primaryColor);
}
.profile-display {
  display: flex;
  align-items: center;
  gap: 10px;
  background: var(--primaryColor);
  border-right: 1px solid #fff;
  border-radius: 10px 0px 0px 10px;
  color: #fff;
  cursor: pointer;
  font-family: "Arial", sans-serif;
  padding: 5px 10px;
  margin-right: 10px;
}

.profile-image {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 2px solid #fff;
}

.profile-details {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.profile-welcome {
  font-size: 12px;
  color: #fff;
}

.profile-name {
  font-size: 14px;
  font-weight: 500;
  color: #fff;
  font-family: var(--SecondaryFont);
  text-transform: capitalize;
}

.dropdown-icon {
  margin-left: auto;
  color: #fff;
}

.custom-dropdown-menu {
  min-width: 180px;
  padding: 10px;
  background: #f1f1f1;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.dropdown-item {
  color: var(--primaryColor);
  font-size: 14px;
  padding: 8px 12px;
  border-radius: 4px;
  text-decoration: none;
  display: block;
  transition: background-color 0.3s;
}

.dropdown-item:hover {
  background: var(--primaryColor);
  color: #fff;
}

.dropdown-dividers {
  height: 0.5px;
  margin: 10px 0;
  background: #fff;
}

.custom-dropdown .dropdown-menu .dropdown-item {
  color: #fff !important;
  font-family: var(--SecondaryFont);
  font-weight: 500;
  font-size: 16;
}
.content-profile {
  padding: 10px;
  width: 150px;
}
</style>
